import React, { useContext, useEffect, useState } from 'react'
import Layout from "../../components/layout"
import "./style.scss"
import { toAbsoluteUrl } from "../../utils/assetHelper"
import { AuthContext } from '../../context/auth'
import { Footer } from "../../components/Footer"
import { getTeamsApi } from '../../api'
import Shapes from "./Shapes/shapes"

const Index = () => {
    const galleryUrl = `${process.env.REACT_APP_GALLERY_URL}300:350`
    const { setTeamModal } = useContext(AuthContext)
    const [teams, setTeams] = useState(  [
    //     {
    //         type: 'orange',
    //         name: "Todd D. Wyman",
    //         position_1: "Chief Executive officer",
    //         position_2: "Co-Founder",
    //         image: toAbsoluteUrl('/media/teams/todd.png'),
    //         imageFull: toAbsoluteUrl('/media/teams/fulltodd.png'),
    //         preview_description: `Todd Wyman retired from Ingersoll Rand as the senior vice president and president of Compression Technologies and Services at the end of 2020.
        
    //     Todd led all aspects of the Compression Technologies and Services strategic busi...`,
    //         description: `Todd Wyman retired from Ingersoll Rand as the senior vice president and president of Compression Technologies and Services at the end of 2020. 
    // Todd led all aspects of the Compression Technologies and Services strategic business unit, focusing on accelerating growth and operational excellence and building a winning culture.

    // He was instrumental in Ingersoll Rand's enterprise-wide value stream transformation and the development of the company's Business Operating System, which is the foundation for the company's growth and operational excellence strategies—Todd was an officer of Ingersoll Rand and a member of the enterprise leadership team.
    // He was a former member of the Board of Trustees for the Manufacturers Alliance for Productivity and Innovation (MAPI); Todd also served on the Ingersoll Rand University Strategy Board and the company's Progressive, Diverse, and Inclusive Strategy Council.

    // Todd joined Ingersoll Rand in 2009 from GE, progressing to increasingly more responsible roles over a 20+ year career in operations, sourcing, and general management, ultimately becoming the Vice President of Global Operations and Supply Chain for GE Transportation. He holds a bachelor's degree in management engineering from Worcester Polytechnic Institute, Worcester, MA.

    // Todd is married to Wendy and has three daughters, Kylie, Allie, and Lainey.`
    //     },
    //     {
    //         type: 'orange',
    //         name: "Dr. Brian T. Bennett",
    //         position_1: "Chief Scientific officer",
    //         position_2: "Co-Founder",
    //         image: toAbsoluteUrl('/media/teams/brian.png'),
    //         imageFull: toAbsoluteUrl('/media/teams/fullbrian.png'),
    //         description: `Dr. Bennett graduated from The University of Massachusetts Amherst and the University of Massachusetts Medical School, receiving his Ph.D. in Biochemistry and Molecular Pharmacology. During Dr. Bennett's graduate work, 2003 - 2006, he was funded by the Department of Defense, ERA of HOPE, program for Breast Cancer Research; he additionally worked for Leica Microsystems during this time supporting 4Pi Super Resolution and contracted with Novus Biologicals as well. Dr. Bennett co-authored three first-author papers, including a groundbreaking publication in the Proceeding of the National Academy of Science (PNAS), demonstrating the power of super resolution in understanding protein aggregation at the site of DNA breaks.

    //     Following graduation, Dr. Bennett continued his work for Leica Microsystems until 2007, when he joined the start-up Lake Placid Biologicals, continuing his work in super resolution for companies like ThermoFisher, Pfizer, and more; Lake Placid Bio, acquired by Active Motif in 2008; following the sale of Lake Placid Bio, Dr. Bennett joined the laboratory of Dr. Erik Jorgensen at the University of Utah. During Brian's time at the University of Utah as a Research Professor, he would design, build, and show a working beta version of what would become the Vutara SR-200. With guidance from the University of Utah TCO, Dr. Bennett created Scintalla Inc. (Later to undergo the name change to Vutara) where, using his previous start-up and business experience, he developed the business plan and financials and single-handedly funded the company to $2.25M. Shortly after funding the project, Dr. Bennett became disenchanted with the field, seeing the "non-profit" system for what it was: a collaboration between the universities and their venture benches to profit from a scientist's dedication to their craft. He exited science for over a decade until he met his good friend Todd Wyman, where he found a partner who believed in him and his passions and offered him a place to build, research, and contribute.
        
    //     Dr. Bennett is married to Jessica K. Bennett, Microscopist and Core Manager for Identifyn™, and with their dog, Roy F'n Kent, they live a peaceful and happy life on an island in Florida, where they collectively pursue their passions for science, friends, and sunsets.`

    //     },
    //     {
    //         type: 'blue',
    //         name: 'Dr. Chandra K. Dixit',
    //         position_1: 'Director of protein chemistry',
    //         position_2: '',
    //         image: toAbsoluteUrl('/media/teams/chandra.png'),
    //         imageFull: toAbsoluteUrl('/media/teams/fullchandra.png'),
    //         description: `Chandra has always had an interest in science since his early school days. After spending a few years in clinical diagnostics back in India, he decided to identify new dimensions of science and moved to Ireland.

    //     He completed his Ph.D. in Biotechnology from Dublin City University in Dublin, Ireland, focusing on antibody engineering and its bioanalytical applications. While studying in Ireland, he took advantage of learning European history by touring Europe.

    //     Upon completing his studies, he decided to apply his expertise in bioanalytical sciences, closely working with the IVD industry in Ireland, Israel, and the United States. Chandra has published 56 papers, including in the journals Nature, Analytical Chemistry, and ACS Nano.

    //     Chandra joined Identifyn™ as the Director of Protein Chemistry, bringing over 20 years of experience in disease diagnostics, assay development, clinical testing, and IVD device development while working with companies like Qiagen and Bristol Myers (Swords Lab). In his new role at Identifyn™, Chandra is creating proprietary methods for reagent formulations and highly specific antibody-fluorophore conjugates for immunocytochemistry application while establishing good manufacturing practices.

    //     Chandra has lived in Sarasota, Florida, for many years with his wife and their two children and enjoys his weekends wandering the beaches with his kids, teaching them about the ecology of island life.`
    //     },
        // {
        //     type: 'blue',
        //     name: 'Dr. Liqin Ren',
        //     title: 'senior scientist',
        //     image: toAbsoluteUrl('/media/teams/liquin.png'),
        //     imageFull: toAbsoluteUrl('/media/teams/fullliquin.png'),
        //     description: `Liqin Ren earned her M.S. degree from Imperial College London in 2016. With a passion for advancing biomedical engineering, specifically the study of fluorescent imaging, she pursued and completed her Ph.D. in the Department of Biomedical Engineering at the University of Texas at Arlington in 2023, publishing five papers and two first-author papers in Nano Research and Biomedical Optics Express.
        //     Dr. Ren joined Identifyn™ as a senior scientist in November 2023, where her expertise in bioengineering and immunofluorescence has positioned her to provide valuable insight and expertise to the Identifyn™ team as she works to validate Super Resolution-specific products and develop Oncological screening in the lab.
        //     Liqin has lived on many continents and experienced diverse cultures and academic communities. She now calls Sarasota, FL, her home, where island life is an excellent fit.`

        // },
        // {
        //     type: 'blue',
        //     name: 'Jessica K. Bennett',
        //     position_1: 'Microscopy facility manager',
        //     position_2: 'microscopist',
        //     image: toAbsoluteUrl('/media/teams/jessica.png'),
        //     imageFull: toAbsoluteUrl('/media/teams/fulljessica.png'),
        //     description: `Jessica K Bennett was there in the beginning, the many discussions about the formation of Identifyn™, providing help and support in convincing Brian to return to the field. Jessica agreed to join Identifyn™ to "help out" account setup, billing, support, and more. However, Jessica found a new interest when the first of many microscopes arrived at the Identifyn™ lab in the summer of 2023. Driven by her motivation to learn, she sat with the Zeiss Apotome, watching all the great videos Zeiss has for training, and began to teach herself what most find difficult. We were happy she found something that combined her background in over a decade of manufacturing and machining experience, and the outcome of her devotion to microscopy reflected in the quality of her work. In 6 months, Jessica was running at the highest level, the Zeiss Apotome, LSM 900 w/ AirScan, and the Elyra 7 with SIM, not to mention doing Single Molecule. Jessica has demonstrated full command not only here at Identifyn™, but she has also gained the respect of our partners in microscopy for her competence in the hardware and software and her eye for imaging.

        // Jessica exemplifies what science should be: motivation, freedom, and support resulting in development and tangible contribution. Jessica is now the Microscopy Core Manager for Identifyn™ and a vital part of our mission to produce and validate products for super resolution, walking the path of the core beliefs on which Identifyn™ was established, doing things differently, allowing our team the access and time to find their passion and where they excel is an inherent culture.

        // Jessica and her husband, Dr. Brian T. Bennett, enjoy the island life, her EDM, and being Roy F'n Kent's mom.`
        // },

        // {
        //     type: 'blue',
        //     name: 'Patrick Amistoso',
        //     position_1: 'Materials & procurement manager',
        //     position_2: "Laboratory Technical Support",
        //     image: toAbsoluteUrl('/media/teams/patrick.png'),
        //     imageFull: toAbsoluteUrl('/media/teams/fullpatrick.png'),
        //     description: `Patrick is a native of Sarasota, Florida. Patrick graduated from Sarasota High School in 2018 and earned his B.A. from the University of Florida in 2023. Patrick joined Identifyn™ in August of 2023, hired on contract by Dr. Bennett to assist in web application development and branding, and his passion was immediately on full display. However, there was more to Patrick. Dr. Bennett and Todd Wyman saw a young man mature beyond his years and with an aptitude for science. Patrick would eagerly and quickly digest the complicated subject matter that is the core competency for Identifyn™. He excelled in organizational duties and quickly moved from a contract to a full-time position as a Materials and Procurement Manager and laboratory technician. Patrick continues to learn and excel.

        // Patrick has the support of his parents and his brother, Ian, who resides in the Philippines and whom Patrick speaks to frequently, sharing in his new adventure.`
        // },
        // {
        //     type: 'blue',
        //     name: 'Emily Simon',
        //     position_1: 'Tissue Culture facility manager',
        //     position_2: 'Microscopy Support',
        //     image: toAbsoluteUrl('/media/teams/emily.png'),
        //     imageFull: toAbsoluteUrl('/media/teams/fullemily.png'),
        //     description: `Emily grew up in Cleveland, Ohio, and received a B.S. in biology from Duquesne University in Pittsburgh, Pennsylvania, in 2020. She first got involved in molecular ecology research at Duquesne, sequencing and comparing the mitochondrial DNA of freshwater sponge species collected in western Pennsylvania. It was through summer internships at the University of North Florida and Harbor Branch Oceanographic Institute that Emily developed a passion for drug discovery, cancer research, and Florida. She continued the research path and completed a M.S. in pharmaceutical sciences at the University of Florida under Dr. Hendrik Luesch in 2022. Her research focused on the extraction, structure elucidation, DNA damage, and biological mechanism of novel drugs from marine cyanobacteria, and she has coauthored a paper in Marine Drugs. 
        
        // When Emily is not in the lab, you can find her snorkeling or scuba diving with her fish friends, making South Florida a perfect place to settle.`
        // },
        // {
        //     type: 'gray',
        //     name: 'Mike Lamach',
        //     position_1: 'Advisor',
        //     position_2: '',
        //     image: toAbsoluteUrl('/media/teams/mike.png'),
        //     imageFull: toAbsoluteUrl('/media/teams/fullmike.png'),
        //     description: `Mike Lamach served as Executive Chair of the Board and CEO of Trane Technologies plc, a global climate innovator focused on sustainable solutions for buildings, homes and transportation through December 2021.

        // Mike served as Board Chair and CEO for twelve years. Under his leadership, Trane Technologies transformed from a diversified industrial manufacturing company, formerly Ingersoll Rand, to an industry-leading climate company with consistent top tier financial and environmental, social and governance (ESG) performance. The company’s total shareholder return over his tenure as CEO was nearly 800%, more than twice the total shareholder return of the S&P 500.
        
        // Mike led the creation of the company’s purpose-driven sustainability strategy, its proprietary business operating system, and its widely recognized culture of engagement. Mike also led multiple strategic evolutions; including the spin-off of the company’s Security Technologies segment into a separate publicly-traded company, Allegion (NYSE:ALLE), in 2013.  In 2020, Mike led the separation of Ingersoll Rand’s Industrial segment, and Reverse Morris Trust merger with Gardner Denver, Inc., to form “new” Ingersoll Rand (NYSE:IR) - culminating in the launch of the company’s Climate Segment, as Trane Technologies (NYSE:TT).
        
        // After joining the company in 2004, Mike led the Security Technologies segment and the Trane Commercial segment before becoming Chief Operating Officer, in early 2009. He was named Chief Executive Officer, in February 2010, and elected Chair of the Board of Directors, in June 2010. Prior to joining the company, Mike served in various leadership roles for Johnson Controls over a 17-year tenure.
        
        // In 2019, Mike was named one of Harvard Business Review’s top performing CEOs and listed by Forbes among America’s 100 Most Innovative Leaders. He is a leader in the manufacturing sector and served as chair of the National Association of Manufacturers from June 2019 until his retirement.
        
        // Mike also serves on the Board of Directors of Honeywell, Inc., Nucor Steel, and PPG Industries.  He is the current Chair of the Charlotte Executive Leadership Council; a member of the Business Council; and serves as Co-Chair for the Charlotte Mayor’s Racial Equity Initiative.  He is a Trustee at Johnson C. Smith University and a member of Duke University’s Fuqua School Business Board of Visitors. He holds a bachelor’s degree in engineering from Michigan State University and a master’s degree in business administration from Duke University. Mike and his family live in Charlotte, NC, where they are actively involved in organizations that serve to create social and economic opportunity for all.
        // `
        // },
        // {
        //     type: 'gray',
        //     name: 'Kevin Lucey',
        //     position_1: 'Advisor',
        //     position_2: '',
        //     image: toAbsoluteUrl('/media/teams/kevin.png'),
        //     imageFull: toAbsoluteUrl('/media/teams/fullkevin.png'),
        //     description: `Kevin Lucey is an advisor to Identifyn, LLC.  He retired after nearly 16 years as Chief Operating Officer and Managing Director at Hamilton Lane (NASDAQ: HLNE), a public firm providing private market investment management services to investors around the world, with nearly $120 billion in discretionary assets under management along with oversight over an additional $700 billion in Advisory Assets as of December 31, 2022.  Hamilton Lane is one of the largest allocators of private markets capital in the world.  Lucey was a member of the firm’s Global Investment Committee, and also led the company’s Operating Committee, Global Business Development activities, Marketing and Public Relations, Distribution Management, and Human Resources functions.

        // Prior to Hamilton Lane, Kevin held a variety of leadership positions at Delaware Investments, Putnam Investments, Mellon Bank and the Boston Company.  He has over 30 years of experience across the Financial Services industry.
         
        // Kevin serves as Chairman of the Board of Trustees at Merrimack College where he earned a Bachelor of Science in Business Administration.  He also serves on the Board of Trustees for the Cobbs Creek Foundation and is an Advisor to RISR, a Fintech company focused on the delivery of valuation services to small and middle-sized businesses through financial intermediaries.
        
        // Kevin resides in Sarasota, FL with his wife Karen.`

        // },

    ])


    const getTeamsData = () => {
        getTeamsApi().then((res) => {
            if (res.data) {
                const leadershipTeam = res?.data?.roles?.find(role => role.name === 'Leadership')?.teams?.sort((a, b) => a.order - b.order)?.map((item) => {
                    return {
                        type: 'orange',
                        description: item.bio,
                        ...item
                    }
                })
                const advisorTeam = res.data.roles.find(role => role.name === 'Advisors')?.teams?.sort((a, b) => a.order - b.order)?.map((item) => {
                    return {
                        type: 'gray',
                        description: item.bio,
                        ...item
                    }
                })
                const Team = res.data.roles.find(role => role.name === 'Team')?.teams?.sort((a, b) => a.order - b.order)?.map((item) => {
                    return {
                        type: 'blue',
                        description: item.bio,
                        ...item
                    }
                })
                setTeams([...teams, ...Team, ...advisorTeam, ...leadershipTeam].sort(customSort))
            }
        })
    }

    useEffect(() => {
        getTeamsData()
    }, [])

    const customSort = (a, b) => {
        const order = { orange: 2, blue: 1, gray: 3 };
        return order[a.type] - order[b.type];
    };

    return (
        <Layout>
            <div className="container title-container bio-container">
                <span style={{ width: '44px', height: '44px', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '12px', background: '#fff2e5', color: 'var(--primary-color)', marginBottom: '20px' }}>
                    <i className="fas fa-users"></i>
                </span>
                <div className="team-text">
                    <h1>Our <span className='team-bio-active'>Team</span>, <span className='team-bio-active'>Leadership</span> & <span className='team-bio-active'>Advisors</span></h1>
                </div>
            </div>
            <div className="teams-wrapper">
                {
                    teams.map((team, index) => {
                        const orangeLast = teams.filter(t => t.type === 'orange')?.length
                        const orangeLastName = teams.filter(t => t.type === 'orange')[orangeLast - 1]?.name
                        const blueLast = teams.filter(t => t.type === 'blue')?.length
                        const blueLastName = teams.filter(t => t.type === 'blue')[blueLast - 1]?.name
                        const grayLast = teams.filter(t => t.type === 'gray')?.length
                        const grayLastName = teams.filter(t => t.type === 'gray')[grayLast - 1]?.name
                        return <div className={`team ${team.type} ${(orangeLastName === team.name) && orangeLast % 2 !== 0 ? 'blue-full' : ''} ${(blueLastName === team.name) && blueLast % 2 !== 0 ? 'blue-full' : ''} ${(grayLastName === team.name) && grayLast % 2 !== 0 ? 'blue-full' : ''}`} key={index + team.name}>
                            <div className="team-img">
                                {team.image_layout ? <Shapes key={team.id} shapeType={team.image_layout} style={{ clipPath: `url(#svgPath-${team.image_layout}-text)`, backgroundImage: `url("${galleryUrl}:${team.image?.split('.com/')[1]}")` }} selectedPhoto={team.image} />
                                    :
                                    <img src={team.image ? galleryUrl + team.image : `${toAbsoluteUrl('/media/teams/blank.png')}`} alt="" style={{ width: '180px', height: '180px' }} />

                                }
                                {/* <img src={team.image} alt="team" style={{ width: '180px', height: '180px' }} /> */}
                            </div>
                            <div className="team-text" style={{width:'100%'}}>
                                <h3>{team.name}</h3>
                                <h5 style={{ marginBottom: 0 }}>{team.position_1}</h5>
                                <h5>{team.position_2}</h5>
                                <p>{team?.description?.substring(0, 310)}{team?.description ? team?.description?.length > 310 && '...' : ''}</p>
                                <button style={{ float: 'right', marginTop: '12px', color: 'var(--primary-color)', fontWeight: 500, fontSize: '16px' }}
                                    onClick={() => setTeamModal({
                                        isOpen: true,
                                        team
                                    })}
                                >Read More</button>
                            </div>
                        </div>
                    })
                }


                {/* <div className="team">
                    <div className="team-img">
                        <img src={toAbsoluteUrl('/media/teams/Blank.png')} alt="team" />
                    </div>
                    <div className="team-text">
                        <h3>Todd D. Wyman</h3>
                        <h5>CHief Executive officer Co-Founder</h5>
                        <p>Lorem ipsum dolor sit amet consectetur. Tempus lobortis sed accumsan vel commodo montes feugiat turpis. Eu sollicitudin nunc urna eget. Vitae et aliquet fringilla vulputate donec netus nunc in. Est sapien at feugiat eget purus erat facilisis.</p>
                        <button style={{ float: 'right', marginTop: '12px', color: 'var(--primary-color', fontWeight: 500, fontSize: '16px' }}
                            onClick={() => setTeamModal({
                                isOpen: true,
                                data: {
                                    name: "Todd D. Wyman",
                                    title: "Chief Executive officer Co-Founder",
                                    image: toAbsoluteUrl('/media/teams/Blank.png'),
                                }
                            })}
                        >Read More</button>
                    </div>
                </div>
                <div className="team">
                    <div className="team-img">
                        <img src={toAbsoluteUrl('/media/teams/Brian.png')} alt="team" />
                    </div>
                    <div className="team-text">
                        <h3>Dr. Brian T. Bennett</h3>
                        <h5>CHief Scientific officer Co-Founder</h5>
                        <p>Lorem ipsum dolor sit amet consectetur. Tempus lobortis sed accumsan vel commodo montes feugiat turpis. Eu sollicitudin nunc urna eget. Vitae et aliquet fringilla vulputate donec netus nunc in. Est sapien at feugiat eget purus erat facilisis.</p>
                        <button style={{ float: 'right', marginTop: '12px', color: 'var(--primary-color', fontWeight: 500, fontSize: '16px' }}>Read More</button>
                    </div>
                </div>
                <div className="team blue">
                    <div className="team-img">
                        <img src={toAbsoluteUrl('/media/teams/Chandra.svg')} alt="team" />
                    </div>
                    <div className="team-text">
                        <h3>Dr. Chandra K. Dixit</h3>
                        <h5>Director of protein chemistry</h5>
                        <p>Lorem ipsum dolor sit amet consectetur. Tempus lobortis sed accumsan vel commodo montes feugiat turpis. Eu sollicitudin nunc urna eget. Vitae et aliquet fringilla vulputate donec netus nunc in. Est sapien at feugiat eget purus erat facilisis.</p>
                    </div>
                </div>
                <div className="team blue">
                    <div className="team-img">
                        <img src={toAbsoluteUrl('/media/teams/Liquin.svg')} alt="team" />
                    </div>
                    <div className="team-text">
                        <h3>Dr. Liqin Ren</h3>
                        <h5>senior scientist</h5>
                        <p>Lorem ipsum dolor sit amet consectetur. Tempus lobortis sed accumsan vel commodo montes feugiat turpis. Eu sollicitudin nunc urna eget. Vitae et aliquet fringilla vulputate donec netus nunc in. Est sapien at feugiat eget purus erat facilisis.</p>
                        <button style={{ float: 'right', marginTop: '12px', color: 'var(--primary-color', fontWeight: 500, fontSize: '16px' }}>Read More</button>
                    </div>
                </div>
                <div className="team blue">
                    <div className="team-img">
                        <img src={toAbsoluteUrl('/media/teams/Jessica.svg')} alt="team" />
                    </div>
                    <div className="team-text">
                        <h3>Jessica K. Bennett</h3>
                        <h5>Microscopy facility manager
                            microscopist</h5>
                        <p>Lorem ipsum dolor sit amet consectetur. Tempus lobortis sed accumsan vel commodo montes feugiat turpis. Eu sollicitudin nunc urna eget. Vitae et aliquet fringilla vulputate donec netus nunc in. Est sapien at feugiat eget purus erat facilisis.</p>
                        <button style={{ float: 'right', marginTop: '12px', color: 'var(--primary-color', fontWeight: 500, fontSize: '16px' }}>Read More</button>
                    </div>
                </div>
                <div className="team blue">
                    <div className="team-img">
                        <img src={toAbsoluteUrl('/media/teams/Patrick.svg')} alt="team" />
                    </div>
                    <div className="team-text">
                        <h3>Patrick Amistoso</h3>
                        <h5>Materials & procurement manager
                            Laboratory Technical Support </h5>
                        <p>Lorem ipsum dolor sit amet consectetur. Tempus lobortis sed accumsan vel commodo montes feugiat turpis. Eu sollicitudin nunc urna eget. Vitae et aliquet fringilla vulputate donec netus nunc in. Est sapien at feugiat eget purus erat facilisis.</p>
                        <button style={{ float: 'right', marginTop: '12px', color: 'var(--primary-color', fontWeight: 500, fontSize: '16px' }}>Read More</button>
                    </div>
                </div>
                <div className="team gray">
                    <div className="team-img">
                        <img src={toAbsoluteUrl('/media/teams/Blank.png')} alt="team" />
                    </div>
                    <div className="team-text">
                        <h3>Mike Lamach</h3>
                        <h5>Advisor</h5>
                        <p>Lorem ipsum dolor sit amet consectetur. Tempus lobortis sed accumsan vel commodo montes feugiat turpis. Eu sollicitudin nunc urna eget. Vitae et aliquet fringilla vulputate donec netus nunc in. Est sapien at feugiat eget purus erat facilisis.</p>
                        <button style={{ float: 'right', marginTop: '12px', color: 'var(--primary-color', fontWeight: 500, fontSize: '16px' }}>Read More</button>
                    </div>
                </div>
                <div className="team gray">
                    <div className="team-img">
                        <img src={toAbsoluteUrl('/media/teams/Blank.png')} alt="team" />
                    </div>
                    <div className="team-text">
                        <h3>Kevin Lucey</h3>
                        <h5>Advisor</h5>
                        <p>Lorem ipsum dolor sit amet consectetur. Tempus lobortis sed accumsan vel commodo montes feugiat turpis. Eu sollicitudin nunc urna eget. Vitae et aliquet fringilla vulputate donec netus nunc in. Est sapien at feugiat eget purus erat facilisis.</p>
                        <button style={{ float: 'right', marginTop: '12px', color: 'var(--primary-color', fontWeight: 500, fontSize: '16px' }}>Read More</button>
                    </div>
                </div> */}
            </div>
            <Footer />
        </Layout>
    )
}

export default Index