import styled from "styled-components";
import { toAbsoluteUrl } from "../../utils/assetHelper";
import ContactBg from "../../assets/contactBg1.png";

export const Header = styled.div`
width: 100%;
display: flex;
flex-direction: column;
gap: 24px;
justify-content: center;
align-items: center;
background: url(${ContactBg}) no-repeat;
background-size: cover;
background-position: center 76%;
height: max-content;    
padding: 160px 6rem 90px 6rem;


@media (max-width: 1100px) {
    padding: 120px 1.2rem 30px 1.2rem;
    height: max-content;
    background-position: center 0;
}

@media screen and (min-width: 2160px) {
    padding-left:  25rem !important;
    padding-right: 25rem !important;
}


h1{
    font-size: 48px;
    font-weight: 800;
    margin-bottom: 16px;
    text-align: center;
    color: var(--white);

    span{
        color: var(--primary-color);
    }
}

.input-container{
    width: 40%;

    @media (max-width: 1100px) {
  width: 100%;
    }
}


`

export const Content = styled.div`
padding: 0px 6rem 100px 6rem;
display: flex;
flex-direction: column;
gap: 27px;
@media (max-width: 1100px) {
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-bottom: 50px;
}

@media screen and (min-width: 2160px) {
    padding-left:  25rem !important;
    padding-right: 25rem !important;
}

.gallery-tab-container {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EDEDED;
    border-radius: 12px;

    button {
        display: block;
        background-color: #EDEDED;
        color: #000;
        text-align: center;
        padding: 16px 12px;
        font-size: 14px;
        font-weight: 500;
        height: 66px;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background-color: rgba(255, 141, 36, 0.28);            
        }

        @media screen and (max-width:900px) {
            height: 60px;
            width: 100%;
            border-radius: 12px;
        }
    }

    .tab-active {
        background-color: #FFF2E5;
        color: var(--primary-color);
        border-radius: 12px;
    }

}

.gallery-tab-container {
    @media screen and (max-width:900px) {
        height: max-content;
        flex-direction: column;
        width: 100%;
        gap: 10px;
        background: transparent;
    }
}



.filter-wrapper{
display: flex;
flex-wrap: wrap;
gap: 10px;
align-items: center;
}

.header-content{
    display: flex;
    justify-content: space-between;
    align-items: center;

    h5{
        font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px;

span{
    font-weight: 700;
}
    }

    .btn{
        display: flex;
        gap: 5px;
        align-items: center;
        background: #FFF2E5;
        color: var(--primary-color);

        &:hover{
        background-color: rgba(255, 141, 36, 0.28);  
        }
    }

    .filter-btn{
        position: relative;
    }

    .filter-dropdown{
        position: fixed;
        top: 0;
        left:0;
        /* border-radius: 20px; */
background:  #FFF;
box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.08);
width: 350px;
z-index: 99;
height: 100vh;
/* height: max-content; */
/* max-height: 450px; */


.filter-dropdown-container{
padding: 16px 20px;
position: relative;
height: 100%;
max-height: calc(100% - 100px);
overflow-y: auto;
}


.filter-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}


h6, h4{
    font-size: 14px;
    font-weight: 600;
line-height: 20px;
display: flex;
gap:8px;

span{
    background-color: #FFF2E5;
    color: var(--primary-color);
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0 8px;
    border-radius: 6px;
}
}

h4{
font-size: 16px;
}



.filter-types{
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;

.filter-type-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter-type-container{
border-radius: 12px;
background: rgba(237, 237, 237, 0.40);
display: flex;
padding: 16px 20px;
flex-direction: column;
gap: 12px;
height: max-content;
max-height: 330px;
overflow-y: auto;

.two-column{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #6c6a73;
    font-size: 16px;
}

}

}

hr{
    margin-top: 12px;
    margin-bottom: 16px;
    height: 1px;
    border: none;
    background-color: #6c6a73;
    width: 100%;
}

.filter-btn-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    padding: 0 20px 16px;

    .btn{
        width: 100%;
        justify-content: center;
        background: var(--primary-color);
        color: #fff;
    }

    .cancel-btn{
        background: #EDEDED;
        color:var(--primary-color)
    }
}

    }

}


.filters-applied-container{
display: flex;
gap: 12px;
margin-bottom: 17px;
flex-wrap: wrap;

.filters{
background-color: rgba(0, 0, 0, 0.12);
border-radius: 8px;
height: 32px;
display: flex;
align-items: center;
gap: 8px;
padding: 4px 12px;
font-size: 16px;
font-weight: 500;
line-height: 24px;
color: #1e1e1e;

span{
    color:#6c6a73;
}

i{
    color: #DC1E00;
    cursor: pointer;
}
}


}

.images-grid-container{
display: flex;
flex-wrap: wrap;
row-gap: 32px;
column-gap: 16px;
justify-content: center;

.react-loading-skeleton, img, video{
object-fit: cover;
/* aspect-ratio: 4/3; */
border-radius: 12px;
cursor: pointer;
    width: auto;
  height: 171px; 
  max-width: 100%;
  max-height: 100%; 

}

}


`

export const MiddleWrapper = styled.div`
     .points-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            width: 100%;
        }

        .points {
            display: flex;
            align-items: start;
            gap: 12px;
            position: relative;
            width: 100%;


            img {
                width: 12px;
                height: 12px;
                transform: translateY(5px);
            }

            p {
                font-size: 20px;
                line-height: 24px;

                span {
                    color: #1e1e1e;
                    font-weight: 600
                }
            }
        }

        .points-right{
            margin-left: 8%;
        }
`